@import "src/styles/variable";

.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.employee-materials-upload-form {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  background-color: $background-blue;

  .form-group {
    width: 100%;
    padding: 24px;
    margin-bottom: 24px;
    background-color: $white;

    .employee-name {
      font-size: 24px;
      color: $amethyst;
    }

    .ant-form-item {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-form-item-label {
    overflow: visible;
  }

  &.ant-form-vertical .ant-form-item-label {
    padding-bottom: 4px;
  }

  .ant-upload-list-item {
    color: $sapphire;

    .anticon {
      color: $gray;
    }

    .ant-upload-list-item-name {
      color: $wave-blue;
    }

    &.ant-upload-list-item-error {
      color: $flamingo;

      .anticon {
        color: $flamingo;
      }

      .ant-upload-list-item-name {
        color: $flamingo;
      }
    }

    &:hover {
      background-color: $background-blue;
    }
  }

  .remove-icon {
    font-size: 14px;
    width: 14px;
    color: $gray;
  }

  .ant-upload-list-item-card-actions-btn {
    height: 22px;
    padding: 0;

    .remove-icon {
      display: inline-flex;
    }
  }

  .form-item-with-desc .ant-form-item-control {
    margin-top: 26px;
  }

  .form-label {
    font-size: 18px;
    color: $wave-blue;
    position: relative;
    font-weight: $font-weight-bold;

    .employee-material-desc {
      position: absolute;
      bottom: -26px;
      font-size: 14px;
      font-weight: $font-weight-normal;
      line-height: 22px;
      color: $amethyst;
      width: 400px;
    }
  }

  .sub-form-label {
    color: $dark-gray;
    margin-left: 4px;
    font-size: 18px;
  }

  .employee-info {
    display: flex;
    align-items: center;
    color: $amethyst;
    font-size: 18px;
    font-weight: $font-weight-bold;

    .form-label {
      color: $amethyst;
      font-size: 18px;
      font-weight: $font-weight-bold;
    }

    .sub-form-label {
      font-size: 18px;
    }
  }

  .split-line {
    width: 1px;
    height: 24px;
    background-color: $middle-gray;
    margin: 0 16px;
  }

  .employee-materials-tip {
    display: flex;
    margin: 32px 0;

    .employee-materials-tip-content {
      display: flex;
      flex-direction: column;

      p {
        line-height: 24px;
        font-size: 16px;
        font-weight: $font-weight-normal;
        color: $amethyst;
        margin: 0;

        &:first-child {
          font-weight: $font-weight-normal;
        }

        &:nth-child(2) {
          margin-bottom: 4px;
          font-weight: $font-weight-normal;
        }
      }
    }

    .info-icon {
      font-size: 20px;
      color: $amethyst;
      margin-top: 2px;
      margin-right: 10px;
    }
  }

  .ant-form-item-label > label.ant-form-item-required {
    &::before {
      display: none;
    }

    &::after {
      display: inline-block;
      margin-left: 4px;
      color: $flamingo;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .file-size-limit {
    padding-left: 16px;
    font-size: 14px;
    line-height: 22px;
    color: $dark-gray;
  }

  .submit-btn {
    margin-top: 24px;
  }

  .ant-form-item-explain-error {
    margin-top: 4px;
  }
}

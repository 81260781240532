.table-column-filter-search-input {
  min-width: 240px;
}

.table-column-filter {
  width: 100%;
  display: inline-flex;
}

.table-column-filter-content-component {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .table-column-filter-close-btn,
  .table-column-filter-confirm-btn {
    margin-left: 10px;
  }
}

.ant-table-filter-dropdown {
  border-radius: 5px;
}

.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .ant-dropdown-trigger.ant-table-filter-trigger {
    color: white;
    font-size: 24px;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;

    &:hover {
      background-color: rgba(255 255 255 / 20%);
    }
  }
}

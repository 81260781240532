@import "src/styles/common";

.button-icon {
  display: flex;
  align-items: center;
  font-size: 24px;

  i:hover {
    color: $sapphire;
  }

  &.disabled {
    cursor: not-allowed;
    color: $gray;

    i:hover {
      color: $gray;
    }
  }
}

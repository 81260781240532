@import "../../../../styles/variable";

.exit-employee-info-not-submitted-badge {
  margin-right: 22px;
}

.exit-employee-info-submitted-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .submitted-badge,
  .reviewed-badge {
    margin-right: 6px;
  }

  .button-icon {
    font-size: 16px;
  }
}

.hidden {
  visibility: hidden;
}

.exit-employee-action-wrapper {
  display: flex;
  justify-content: center;

  .initiate-signing-button {
    display: flex;
    margin-left: 16px;
    min-width: 90px;
    width: 90px;
    align-self: center;
    justify-content: center;
    align-items: center;

    .ant-badge-status-text {
      margin: 0 8px;
    }
  }

  .info-preview-button {
    width: 40%;
    min-width: 9rem;
  }

  .exit-hro-initiate-approval-button {
    margin-left: 16px;
  }

  .hidden {
    visibility: hidden;
    width: 90px;
  }
}

.initializing-file-badge {
  margin-right: 6px;
  line-height: 2.5;
}

.preview-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 100%;

  .button-badge {
    border: none;
    background-color: transparent;
    color: transparent;
    box-shadow: none;
    padding: 0;
    outline: none;
    height: 100%;

    button {
      border: none;
      background-color: transparent;
      color: transparent;
      box-shadow: none;
      padding: 0;
      outline: none;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }

    .initializing-file-badge {
      line-height: 100%;
    }
  }
}

.ri-eye-line {
  &:hover {
    cursor: pointer;
  }
}

.exit-employee-list-table .ant-table-row {
  height: 67px;
}

@import "src/styles/variable";

.skill-info-section {
  background-color: white;
  padding: 24px;
  margin-bottom: 24px;
}

.skill-info-section .ant-form-item {
  margin-bottom: 16px;
}

.skill-info-section:last-child {
  margin-bottom: 0;
}

.skill-info-section .ant-form-item.file-upload-item {
  margin-bottom: 0;
}

.skill-info-section .ant-form-item.github-item {
  margin-bottom: 0;
}

.other-skill-item input {
  width: 557px;
}

.github-item input {
  width: 216px;
}

.skill-info-section .hint {
  color: $dark-gray;
  margin: 4px 0 0;
}

.add-skill-title {
  color: $wave-blue;
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: $font-weight-bold;
}

.add-item-btn,
.add-item-btn:focus,
.add-item-btn:active {
  border: 1px dashed $wave-blue;
  color: #003d4f;
  height: 34px;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  border-radius: 6px;
}

.add-item-btn:hover {
  border-color: $sapphire;
  color: $sapphire;
}

.add-item-btn:disabled {
  border-color: $gray;
  background: $neutral-light-gray;
  color: $gray;
}

.skill-block {
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 16px;
}

.remove-block-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  color: $wave-blue;
  font-size: 20px;
}

.file-size-limit {
  padding-left: 8px;
  font-size: 14px;
  line-height: 22px;
  color: $dark-gray;
}

.ant-upload-list-item {
  color: $sapphire;

  .anticon {
    color: $gray;
  }

  .ant-upload-list-item-name {
    color: $wave-blue;
  }

  &.ant-upload-list-item-error {
    color: $flamingo;

    .anticon {
      color: $flamingo;
    }

    .ant-upload-list-item-name {
      color: $flamingo;
    }
  }

  &:hover {
    background-color: $background-blue;
  }
}

.ant-upload-list-item-card-actions-btn {
  .remove-icon {
    display: inline-flex;
  }
}

.skill-block .ant-upload-list-item-card-actions-btn {
  height: 22px;
  padding: 0;
}

@import "src/styles/variable";

.main-page {
  margin: 0 auto;
  background: $background-blue;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 700px;

  .container {
    padding: $px22 $px24;
    background: $white;
  }

  .ant-tabs-tab {
    height: 48px;
    font-style: normal;
    margin-bottom: 10px;
    color: $black;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $middle-flamingo;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

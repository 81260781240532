@import "../../../../styles/variable";

.exit-add-employee-modal > .ant-modal > .ant-modal-content {
  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-body {
    padding: 0 32px;

    .input-label {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      span {
        color: $flamingo;
      }
    }

    p {
      margin-left: 14px;
    }
  }

  .ant-modal-footer {
    .ant-btn {
      height: 34px;
      padding: 6px 16px;
      border-radius: 4px;
      line-height: 22px;
    }
  }
}

@import "src/styles/variable";

.custom-tooltip-title {
  display: flex;
  align-items: center;
}

.anticon.alert-icon {
  font-size: 14px;
  color: $turmeric-yellow;
  margin-right: 10px;
}

.tooltip-title-content {
  white-space: pre-line;
  margin: 0;
  color: $black;
  padding: 6px 0;
}

@import "src/styles/variable";

.ant-drawer-wrapper-body {
  .title {
    margin-right: 16px;
  }

  .employee-id {
    color: #634f7d;
  }

  .ant-drawer-close {
    order: 2;
  }

  .preview-sub-div {
    margin-bottom: 16px;
    margin-top: -8px;
  }

  .preview-check-box-item {
    margin-top: 8px;

    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: rgba(0 0 0 / 25%);
    }
  }

  .preview-check-box-other-text {
    margin-top: 8px;
  }
}

.certificate-reject-reason-input-modal {
  .ant-modal-content {
    width: 416px;
    height: 297px;
  }

  .ant-modal-body {
    padding: 24px 24px 20px;
    margin: 0 auto;
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }
}

.certificate-reject-success-modal {
  .ant-modal-content {
    width: 416px;
    height: 297px;
  }

  .ant-modal-body {
    height: 190px;
    padding-top: 100px;
  }
}

@import "src/styles/variable";

.side-bar {
  background: #6b9e78;
  width: 208px;
  position: relative;
  height: 100%;

  .ant-menu-root.ant-menu-inline,
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right {
    position: absolute;
    height: 100%;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title {
    margin: 0;
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-vertical .ant-menu-item {
    &:first-child {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .ant-menu-vertical .ant-menu-submenu,
  .ant-menu-vertical-left .ant-menu-submenu,
  .ant-menu-vertical-right .ant-menu-submenu,
  .ant-menu-inline .ant-menu-submenu {
    &:first-child {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    &:hover {
      background: $background-blue;
    }
  }

  .ant-menu-title-content {
    color: $wave-blue;

    a {
      color: $wave-blue;

      &:hover {
        color: $wave-blue;
      }
    }
  }

  .menu-icon {
    color: $wave-blue;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $white;

    a {
      color: $wave-flamingo;
    }

    .menu-icon {
      color: $wave-flamingo;
    }
  }

  .ant-menu-inline .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-vertical .ant-menu-item::after {
    border-right: 3px solid $wave-flamingo;
  }

  .ant-menu-submenu-arrow {
    color: $wave-blue;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: $wave-blue;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: $white;
  }

  .change-role-toggle-container {
    position: absolute;
    bottom: 60px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import "src/styles/variable";

.operation-list {
  display: flex;
  width: 104px;
  height: 102px;
  padding: 4px 0;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  a {
    display: flex;
    padding: 5px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: white;
    color: black;

    &:hover {
      background: #f7fafc;
      color: #47a1ad;
    }
  }
}

.ri-error-warning-fill {
  color: $turmeric-yellow;
  font-size: 24px;
}

.ri-more-fill {
  font-size: 24px;

  &:hover {
    cursor: pointer;
  }
}

.ant-modal-body {
  margin-top: 30%;
  padding: 32px 32px 24px;
}

.ant-modal-footer {
  padding: 0 32px 24px;
  text-align: right;
  background: transparent;
  border-top: none;
  border-radius: 0 0 2px 2px;
}

.modal-content {
  display: flex;
  gap: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-family: "Noto Sans SC", serif;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
  }

  .modal-item {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    p {
      width: 100%;
      margin: 0;
    }

    .content {
      color: #909090;
    }
  }
}

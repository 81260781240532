@import "src/styles/variable";

.exit-personal-information-form {
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;

  .exit-personal-information-edit {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    margin: 0 120px;

    .exit-personal-information-header-tip {
      padding: 0 0 24px;
      color: $amethyst;
      font-size: 16px;
      display: flex;

      .info-icon {
        font-size: 20px;
        margin-right: 10px;
        margin-top: 3px;
      }

      p {
        margin: 0 0 8px;
      }
    }

    .form-group {
      width: 100%;
      background-color: $white;
      padding: 24px;
      margin-bottom: 24px;

      .ant-form-item-label {
        overflow: visible;
      }

      .description {
        margin-bottom: 16px;
      }
    }
  }

  .submit-btn-form-item .ant-form-item-control-input-content {
    display: flex;

    .submit-btn {
      display: flex;
      margin-inline-start: auto;
      margin-right: 140px;
    }
  }
}

.ant-input.other-input {
  &:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: $gray;
  }

  &:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: $gray;
    box-shadow: none;
  }

  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: $gray;
}

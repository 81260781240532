@import "src/styles/variable";

.preview-check-container {
  flex: 1;
  min-height: 0;
  min-width: 0;

  .preview-check-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 24px;
    background-color: white;
  }

  .employee-basic-info-container {
    display: flex;
    justify-content: space-between;
  }

  .employee-basic-info {
    display: flex;
    align-items: center;
    color: $amethyst;
    font-weight: $font-weight-bold;

    .employee-name {
      font-size: 24px;
      line-height: 32px;
      color: $black;
      margin-right: 16px;
    }

    .employee-id {
      font-size: 18px;
      line-height: 26px;
      margin-right: 48px;
    }

    .employee-info-item {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      margin-right: 8px;
      font-weight: $font-weight-normal;

      &::after {
        content: "";
        display: inline-block;
        margin-left: 8px;
        height: 24px;
        width: 1px;
        background-color: $middle-gray;
      }

      &.last-item::after {
        display: none;
      }
    }
  }

  .empty-container {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .ant-tabs {
    flex: 1;
    margin-top: 14px;
    color: $wave-blue;
    height: calc(100% - 78px);

    .ant-tabs-ink-bar {
      background-color: $flamingo;
    }

    .ant-tabs-tab:hover {
      color: $flamingo;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $flamingo;
      font-weight: $font-weight-bold;
    }

    .ant-tabs-content {
      background-color: $background-blue;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-tabs-tabpane {
        width: 100%;
        text-align: center;
        overflow: auto;
        padding: 24px;
      }

      /* stylelint-disable-next-line */
      .react-pdf__Document {
        width: 100%;

        /* stylelint-disable-next-line */
        .react-pdf__message {
          min-height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      /* stylelint-disable-next-line */
      .react-pdf__Page {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ant-btn {
    margin-right: 8px;
  }
}

.reject-info-pop {
  color: $gray;
  font-size: 14px;
}

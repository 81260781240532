@import "src/styles/variable";

.drawer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin: 0;
    height: 100%;
  }

  .drawer-close {
    border-radius: 6px;
    border: 1px solid #003d4f;
    background: #002b37;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    color: $white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-transform: capitalize;

    &:hover {
      cursor: pointer;
    }
  }
}

.approval-drawer-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: fit-content;

  .content-left {
    .circle {
      margin-top: 5px;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: $white;
    }

    .vertical-line {
      margin-left: 5px;
      width: 21px;
      height: calc(100% - 10px);
      border-left: solid 1px $white-smoke;
    }
  }

  .content-right {
    padding-bottom: 20px;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin: 0;
    }
  }
}

@import "src/styles/common";

.carousel {
  height: 581px;
  display: flex;
  flex-direction: row;

  .preview-image {
    width: 672px;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-content {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-icon {
    color: $black;

    &[disabled] {
      color: $gray;
    }
  }

  i,
  i::before {
    font-size: 24px;
    flex-shrink: 0;
    color: inherit;
  }
}

.contract-check-modal {
  .ant-modal-body {
    height: 714px;
    padding: 24px;

    .preview-check-container {
      height: 100%;
    }
  }

  .preview-check-content {
    padding: 0;
  }
}

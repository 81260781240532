@import "./variable";

/* stylelint-disable max-line-length */
// flex
.flex {
  display: flex;
}

// head
.headline3 {
  height: 48px;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: $font-size-28;
  color: $black;
  margin-bottom: $px24;
}

.headline4 {
  height: 28px;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: $font-size-24;
  color: $black;
  margin-bottom: $px22;
}

.headline5 {
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: 18px;
  line-height: 26px;
}

// text
.large-text {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 24px;
  line-height: 32px;
}

.medium-text {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 26px;
}

.small-text {
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: 14px;
  line-height: 22px;
}

// pagination
.ant-pagination-item-active {
  border-color: $sapphire;

  a {
    color: $sapphire;
  }
}

.ant-pagination-item:hover {
  border-color: $sapphire;

  a {
    color: $sapphire;
  }
}

// breadcrumb
.ant-breadcrumb {
  /* stylelint-disable-next-line */
  a {
    color: $dark-gray !important;
  }

  li:last-child a {
    color: $black !important;
  }
}

// button
.ant-btn {
  height: 40px;
  padding: 8px 16px;
  font-weight: $font-weight-normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 6px;

  &.ant-btn-lg {
    height: 58px;
    padding: 16px 64px;
    font-size: 18px;
    line-height: 20px;
    border-radius: 6px;
  }

  &.ant-btn-sm {
    height: 34px;
    padding: 6px 16px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
  }

  &.ant-btn-primary {
    background-color: $wave-blue;
    border-color: $wave-blue;
    color: $white;

    &:hover {
      background-color: $light-blue;
      border-color: $light-blue;
    }

    &:active {
      background-color: $dark-blue;
      border-color: $dark-blue;
    }

    &[disabled] {
      background-color: $gray;
      border-color: $gray;
      color: white;
    }
  }

  &.ant-btn-default {
    background-color: $white;
    border-color: $wave-blue;
    color: $wave-blue;

    &:hover {
      background-color: $white;
      border-color: $sapphire;
      color: $sapphire;
    }

    &:active {
      color: $white;
      background-color: $sapphire;
      border-color: $sapphire;
    }

    &[disabled] {
      background-color: $background-blue;
      border-color: $gray;
      color: $gray;
    }
  }

  /* stylelint-disable-next-line */
  &.ant-btn-text,
  &.ant-btn-text a {
    color: $wave-blue;
    background-color: transparent;

    &:hover {
      color: $sapphire;
    }

    &:active {
      color: $dark-blue;
    }

    &[disabled] {
      color: $gray;
    }
  }
}

// select
.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: $middle-gray;
    border-radius: 6px;
  }

  &:hover .ant-select-selector {
    border: 1px solid $middle-gray;
  }

  &.ant-select-focused .ant-select-selector {
    border: 1px solid #47a1ad;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: $white-smoke;
  color: $dark-gray;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $sapphire;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $background-blue;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $light-sapphire;
  font-weight: $font-weight-normal;
}

// pagination
.ant-pagination {
  font-weight: $font-weight-bold;
}

.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  border-color: $sapphire;

  a {
    color: $sapphire;
  }
}

.ant-pagination-disabled:hover .ant-pagination-item-link {
  border-color: #d9d9d9;
}

.ant-pagination-jump-next:hover .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $sapphire;
}

.ant-table-pagination.ant-pagination {
  margin: 24px 24px 24px 0;
}

// tabs
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav {
  margin: 0;
  padding-right: $px16;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  background: $background-blue;
  margin-right: $px4;
  padding: $px8 $px16;
  border-radius: $px6 $px6 0 0;

  a {
    display: inline-block;
    margin-right: $px16;
    font-size: $font-size-24;
    color: $wave-blue;
  }

  .ant-badge-count {
    font-size: $font-size-24;
    box-shadow: 0 0 0 1px $middle-gray;
    background: $middle-gray;
    color: $wave-blue;
    padding: $px4 $px6;
    height: $px28;
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: $flamingo;

  a {
    color: $white;
    font-weight: $font-weight-bold;
  }

  .ant-badge-count {
    box-shadow: 0 0 0 1px $white;
    background: $white;
    color: $flamingo;
    font-weight: $font-weight-bold;
  }
}

// table
.ant-table {
  border-bottom: $px1 solid $background-blue;

  .align-center {
    text-align: center;
  }

  .show-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-table-body {
    min-height: 335px;
  }

  .ant-table-body tr:nth-child(odd) {
    background: $background-blue;
  }

  .ant-table-body tr:nth-child(even) {
    background: $white;
  }

  tr:nth-child(odd) .ant-table-cell-fix-left {
    background: $background-blue;
  }

  tr:nth-child(odd) .ant-table-cell-fix-right {
    background: $background-blue;
  }

  tr:first-child .ant-table-cell-fix-left {
    background: $wave-blue;
  }

  tr:first-child .ant-table-cell-fix-right {
    background: $wave-blue;
  }

  .ant-table-header {
    border-radius: 15px 15px 0 0;
  }

  .ant-table-thead > tr > th {
    background: $wave-blue;
    font-size: $font-size-14;
    color: $white;
    font-weight: $font-weight-bold;
  }

  .ant-table-thead > tr > th.ant-table-cell-scrollbar {
    display: none;

    &::before {
      display: none;
    }
  }

  .ant-table-thead > tr > th:not(:last-child, .ant-table-selection-column, .ant-table-row-expand-icon-cell, [colspan]).last-td::before {
    background: $wave-blue;
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: 0 0 0 0 $wave-blue;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: transparent;
  }

  .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child,
  .ant-table tfoot > tr > td:first-child,
  .ant-table tfoot > tr > th:first-child {
    padding: 16px 16px 16px 18px;
  }

  tr.ant-table-row:hover {
    td.ant-table-cell-row-hover {
      background: $light-sapphire;
    }

    td:first-child {
      border-left: 2px solid $sapphire;
      padding: 16px;
    }
  }

  .ant-table-footer {
    padding: 8px 24px;
    margin-top: 16px;
    background: $background-blue;
  }

  .ant-empty-description {
    color: $dark-gray;
    font-size: 18px;
  }
}

/* stylelint-disable selector-not-notation */
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: $neutral-light-gray;
}

/* stylelint-disable color-function-notation */
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 6px 0 4px -4px rgba(0, 0, 0, 10%);
  right: 6px;
}

// message
.ant-message {
  left: 100px;

  .ant-message-success .anticon {
    color: $jade-green;
  }

  .ant-message-error .anticon {
    color: $flamingo;
  }

  .ant-message-info .anticon {
    color: $sapphire;
  }
}

// ant-steps
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 100% !important;
}

.ant-steps {
  .ant-steps-item-title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 2px;
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: $neutral-light-gray;
      border-color: transparent;

      > .ant-steps-icon {
        color: $gray;
      }
    }

    > .ant-steps-item-container > .ant-steps-item-content {
      > .ant-steps-item-title {
        color: $dark-gray;

        &::after {
          background-color: $middle-gray;
        }
      }

      > .ant-steps-item-description {
        color: $dark-gray;
      }
    }
  }

  .ant-steps-item-finish {
    > .ant-steps-item-container > .ant-steps-item-content {
      > .ant-steps-item-description {
        color: $dark-gray;
      }

      > .ant-steps-item-title::after {
        background-color: $wave-blue;
      }
    }

    .ant-steps-item-icon {
      border-color: $wave-blue;
      border-width: 2px;

      > .ant-steps-icon {
        color: $wave-blue;
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      border-color: $wave-blue;
    }

    > .ant-steps-item-container {
      > .ant-steps-item-icon {
        background-color: $wave-blue;
      }

      > .ant-steps-item-content {
        > .ant-steps-item-description {
          color: $dark-gray;
        }

        > .ant-steps-item-title::after {
          background-color: $middle-gray;
        }
      }
    }
  }

  /* stylelint-disable-next-line */
  .ant-steps-item:not(.ant-steps-item-active, .ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
    border-color: $sapphire !important;

    .ant-steps-icon {
      color: $sapphire !important;
    }
  }

  .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover {
    .ant-steps-item-title,
    .ant-steps-item-description {
      color: $sapphire !important;
    }
  }
}

// ant-tooltip
.ant-tooltip {
  color: $white;

  .ant-tooltip-inner {
    background-color: $white;
    color: $amethyst;
    border-radius: 4px;
  }

  .ant-tooltip-arrow-content::before {
    background: $white;
  }
}

// input
.ant-input {
  border-color: $middle-gray;
  border-radius: 6px;

  &[disabled] {
    background: $white-smoke;
    color: $dark-gray;
    border-color: $middle-gray;
  }

  &:hover {
    border-color: $sapphire;
  }

  &:focus {
    border: 1px solid #47a1ad;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  }

  .ant-select-arrow {
    color: $middle-gray;
  }

  &.ant-input-status-error:not(.ant-input-disabled, .ant-input-borderless).ant-input:focus {
    border-color: $flamingo;
    box-shadow: none;
  }
}

.ant-input-number-disabled {
  background: $white-smoke;
  color: $dark-gray;
  border-color: $middle-gray;
  border-radius: 6px;
}

// datePicker
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: $sapphire;
  border-color: $sapphire;
}

.ant-picker {
  border-radius: 6px;

  &:hover {
    border-color: $sapphire;
  }

  .ant-picker-cell .ant-picker-cell-in-view {
    background-color: $white;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: $sapphire;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected, .ant-picker-cell-range-start, .ant-picker-cell-range-end, .ant-picker-cell-range-hover-start, .ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: $sapphire;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: $light-sapphire;
  }

  .ant-picker-cell-inner-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range, .ant-picker-cell-range-start, .ant-picker-cell-range-end),
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range, .ant-picker-cell-range-start, .ant-picker-cell-range-end),
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range) {
    &::after {
      border-color: $sapphire;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #d1e7ea;
  }

  &.ant-picker-range .ant-picker-active-bar {
    background: $sapphire;
  }
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: $sapphire;
}

.ant-picker-header-view button:hover {
  color: $sapphire;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $sapphire;
}

.ant-picker-today-btn {
  color: $sapphire;

  &:hover {
    color: $sapphire;
  }
}

.ant-picker-input > input[disabled] {
  color: $dark-gray;
}

.ant-picker-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

// checkbox
.ant-checkbox {
  .ant-checkbox-inner {
    border-color: $middle-gray;
    width: 18px;
    height: 18px;
  }

  &.ant-checkbox-checked .ant-checkbox-inner {
    border-color: $sapphire;
    background-color: $sapphire;
  }
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $sapphire;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $sapphire;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f5f5f5;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: transparent;
}

// form
.ant-form-item-explain-error {
  color: $flamingo;
  font-size: 14px;
  line-height: 22px;
}

// radio
.ant-radio-checked .ant-radio-inner {
  border-color: $sapphire;
}

.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: $sapphire;
}

.ant-radio-inner::after {
  background-color: $sapphire;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  font-size: 14px;
}

.ant-radio-group {
  .ant-radio-button-wrapper {
    border-color: $middle-gray;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &:hover {
      color: $sapphire;
    }

    &:focus-within {
      box-shadow: $sapphire;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $black;
      background-color: $sapphire;
      border-color: $sapphire;

      &::before {
        background-color: $sapphire;
      }
    }
  }
}

// popConfirm
.ant-popover-inner-content {
  padding: 16px;
}

.ant-popover-message {
  font-size: $px18;

  .ant-popover-message-icon {
    margin-right: 12px;
    color: #cc8561;
    padding: 4px 0;
  }
}

// ant-modal
.ant-modal-content {
  .ant-modal-title {
    font-weight: $font-weight-bold;
    font-size: 24px;
    line-height: 32px;
  }
}

// dashed button
.ant-btn-dashed,
.ant-btn-dashed:focus,
.ant-btn-dashed:active {
  border: 1px dashed $wave-blue;
  color: #003d4f;
  height: 34px;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  border-radius: 6px;
}

// upload
.ant-upload-list-item:hover {
  .ant-upload-list-item-card-actions .anticon.remove-icon {
    color: $sapphire;
  }
}

.ant-btn-dashed:hover {
  border-color: $sapphire;
  color: $sapphire;
}

.ant-btn-dashed:disabled {
  border-color: $gray;
  background: $neutral-light-gray;
  color: $gray;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable-next-line */
.ant-progress-success-bg, .ant-progress-bg {
  background-color: $sapphire;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: $jade-green;
}

.ant-spin-dot-item {
  background-color: $sapphire;
}

@import "src/styles/variable";

.employee {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  background-color: $background-blue;

  // height: calc(100vh - #{$platform-header-height} - #{$platform-footer-height});
  height: calc(100vh - #{$platform-header-height});

  .employee-sider {
    width: 208px;
  }

  .employee-container {
    background-color: $background-blue;
    width: 100%;
    margin: 0;
    display: flex;
    padding: 24px;
    flex-direction: column;
    overflow-y: auto;

    .ant-layout {
      background-color: transparent;
    }
  }

  .breadcrumb {
    flex: 0 0 auto;
    padding: 0 24px 32px 0;

    .breadcrumb-title {
      color: $dark-gray;
    }
  }
}

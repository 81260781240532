@import "src/styles/variable";

.employee-contract-list {
  .ant-btn-text a {
    margin-left: 8px;
  }

  .ant-btn-text:hover a {
    color: $sapphire;
  }

  .ant-btn-text:active a {
    color: $dark-blue;
  }

  .operation-button-column {
    display: inline-flex;
    align-items: center;

    .operation-item-buttons {
      min-width: 260px;
    }
  }

  .exit-approval-detail-button {
    display: inline-flex;
  }
}

.hidden {
  visibility: hidden;
}

.footer-container {
  display: flex;
  justify-content: space-between;

  .selected-employee-number {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    padding: 5px 0;
  }

  .cancel-selection {
    margin-right: 8px;
  }
}

.archived-badge {
  margin: 0 14px;
}

.archiving-badge {
  margin: 0 14px;
}

@import "../../../../styles/variable";

.exit-to-be-signed-table {
  .info-badge-placeholder {
    margin-right: 10px;
    width: 200px;
  }

  .info-badge {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    margin-right: 10px;
    width: 200px;
    justify-content: center;

    .ri-information-fill {
      font-size: 16px;
      color: #f2617a;
      margin: auto 10px;
    }

    .button-badge {
      border: none;
      background-color: transparent;
      color: transparent;
      box-shadow: none;
      padding: 0;
      outline: none;
      height: 100%;

      button {
        border: none;
        background-color: transparent;
        color: transparent;
        box-shadow: none;
        padding: 0;
        outline: none;
        height: 100%;
      }

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .exit-to-be-signed-operation-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .operation-button-wrapper {
      flex: 1;
    }
  }
}

.ant-tooltip {
  max-width: max-content;
}

.operation-items {
  display: flex;
}

@import "src/styles/variable";

.employee-container #employee-edit .form-group .tip-info-content p {
  color: $amethyst;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &.chinese-content {
    margin-bottom: 0;
  }

  &.english-content {
    margin-bottom: 24px;
  }

  & a {
    color: $amethyst;
    text-decoration: underline;

    &:hover {
      color: $wave-flamingo;
      text-decoration: none;
    }
  }
}

// color
$black: #000;
$white: #fff;
$middle-gray: #d5d9db;
$gray: #909090;
$light-gray: #eef1f3;
$background-blue: #f7fafc;
$light-blue: #59727f;
$light-sapphire: #edf6f7;
$sapphire: #47a1ad;
$wave-blue: #003d4f;
$dark-blue: #002b37;
$light-flamingo: #e0697b;
$middle-flamingo: #f2617a;
$flamingo: #f2617a;
$wave-flamingo: #bd4257;
$amethyst: #634f7d;
$jade-green: #6b9e78;
$turmeric-yellow: #cc850a;
$neutral-light-gray: #edf1f3;
$white-smoke: #f5f5f5;
$dark-gray: #605e5e;

// font
$px44: 44px;
$px34: 34px;
$px28: 28px;
$px26: 26px;
$px24: 24px;
$px22: 22px;
$px18: 18px;
$px16: 16px;
$px14: 14px;
$px8: 8px;
$px6: 6px;
$px4: 4px;
$px2: 2px;
$px1: 1px;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-size-28: $px28;
$font-size-24: $px24;
$font-size-18: $px18;
$font-size-14: $px14;

// platform style
$platform-header-height: 80px;
$platform-footer-height: 60px;

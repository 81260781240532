@import "src/styles/variable";

.exit-personal-approval-form {
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;

  a {
    color: $amethyst;
    text-decoration: underline;

    &:hover {
      color: $wave-flamingo;
      text-decoration: none;
    }
  }

  .exit-personal-approval-edit {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    margin: 0 120px;

    .exit-personal-approval-header-tip {
      color: $amethyst;
      font-size: 16px;
      display: flex;

      .info-icon {
        font-size: 16px;
        margin: 4px 10px 0 0;
      }
    }

    .exit-personal-approval-header-title {
      p {
        color: $wave-blue;
        font-family: "Noto Sans SC", serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin: 0 0 8px;
      }
    }

    .form-group {
      width: 100%;
      background-color: $white;
      padding: 24px;
      margin-bottom: 24px;

      .ant-form-item-label {
        overflow: visible;
      }

      &-header {
        width: 100%;
        background-color: $white;
        padding: 16px 24px;
        margin-bottom: 24px;
      }
    }

    .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
      align-items: center;
      margin-top: 16px;
    }

    .ant-checkbox-checked::after {
      height: 18px;
    }

    .exit-personal-approval-first-form-item {
      margin-top: 0;
    }

    .exit-personal-approval-normal-form-item {
      margin-top: 16px;
    }

    .exit-personal-approval-last-form-item {
      margin-top: 8px;
    }

    .exit-personal-approval-contact-person-span {
      margin-top: 16px;
    }

    .sub-form-label {
      margin-left: 0;
    }

    .main-form-label {
      margin-right: 4px;
    }

    .ant-checkbox {
      height: 22px;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;

    .submit-btn {
      display: flex;
      margin-inline-start: auto;
      margin-right: 140px;
    }
  }
}

.exit-personal-approval-form-item-label {
  color: $sapphire;
  font-family: "Noto Sans SC", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

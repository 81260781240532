@import "src/styles/variable";

.main-page {
  margin: 0 auto;
  background: $background-blue;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 700px;

  .container {
    padding: $px22 $px24;
    background: $white;
  }
}

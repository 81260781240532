@import "../../../../../styles/variable";

.initiate-approval-modal {
  .ant-modal-header {
    border: none;
  }

  .ant-modal-footer {
    border: none;
    padding: 10px 24px 80px;
  }

  .input-label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;

    span {
      color: $flamingo;
    }
  }

  .select {
    padding-left: 10px;

    .ant-select-selection-search-input {
      letter-spacing: 1px;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 6px;

    .ant-input:focus {
      border: none;
    }
  }
}

@import "src/styles/variable";

.anticon.comment-message-icon {
  font-size: 22px;
  color: $dark-blue;
  margin-right: 10px;

  &:hover {
    color: $sapphire;
  }
}

.anticon.arrow-go-back-line {
  font-size: 22px;
  color: $dark-blue;
  margin-right: 10px;

  &:hover {
    color: $sapphire;
  }
}

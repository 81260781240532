@import "src/styles/variable";

.no-download-image {
  width: 168px;
  height: 172px;
  margin-top: 24px;
}

.no-download-image + div {
  margin-top: 24px;
  font-size: 18px;
  color: $dark-gray;
}

.download-btn {
  position: relative;
}

.my-contract-container {
  height: 100%;
  display: flex;

  .sub-form-label {
    color: $dark-gray;
    margin-left: 4px;
    font-size: 18px;
  }

  .preview-check-container .employee-basic-info .employee-name {
    font-size: 24px;
    color: $amethyst;
  }

  .employee-info {
    display: flex;
    align-items: center;
    color: $amethyst;
    font-size: 18px;
    font-weight: $font-weight-bold;

    .form-label {
      color: $amethyst;
      font-size: 18px;
      font-weight: $font-weight-bold;
    }

    .sub-form-label {
      font-size: 18px;
    }
  }

  .split-line {
    width: 1px;
    height: 24px;
    background-color: $middle-gray;
    margin: 0 16px;
  }
}

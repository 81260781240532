@import "src/styles/variable";

.drawer-content {
  .ant-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-tabs-top {
    height: auto;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-tab-btn:active {
    color: $flamingo;
  }

  .ant-tabs {
    flex: 1;
    margin-top: 14px;

    .ant-tabs-ink-bar {
      background-color: $flamingo;
    }

    .ant-tabs-tab:hover {
      color: $flamingo;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $flamingo;
      font-weight: $font-weight-bold;
    }
  }
}

.title {
  margin-right: 16px;
}

.employee-id {
  color: #634f7d;
}

.individual-info-status {
  .ant-tooltip .ant-tooltip-inner {
    color: $amethyst;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 22px;
    text-shadow: none;
  }
}

.sub-title {
  margin-bottom: 24px;
}

.headline4.supplement-title {
  display: flex;
  margin-top: 28px;
  height: 32px;
}

@import "../../../../../styles/variable";

.hrbp-review-modal {
  .ant-modal-header {
    border: none;
    padding: 18px 64px 14px 36px;
    border-bottom: 1px solid #d5d9db;
  }

  .ant-modal-content {
    .ant-modal-close {
      top: 10px;
    }

    .ant-modal-body {
      padding: 30px 24px 28px;
      font-size: 14px;

      .hrbp-preview,
      .hrbp-review {
        width: 424px;
        display: flex;
      }
    }
  }

  .ant-modal-footer {
    border: none;
    padding: 0 24px 24px;

    .ant-btn {
      padding: 6px 16px;
      width: 68px;
      height: 34px;
      border-radius: 4px;

      span {
        font-size: 14px;
      }
    }
  }

  .hrbp-review-checked {
    .ant-steps {
      margin-bottom: 20px;

      .input-label {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 24px;

        span {
          font-size: 14px;
          color: $flamingo;
        }
      }

      .ant-steps-item-content .hrbp-review,
      .ant-steps-item-content .hrbp-preview {
        display: flex;

        .hrbp-preview-btn,
        .hrbp-review-btn {
          flex: 1;
          text-align: right;

          .review-cancel {
            margin-right: 10px;
          }

          .review-ok:not([disabled]) {
            background-color: $dark-blue;
            color: white;
          }

          a span {
            line-height: 32px;
          }

          .go-preview {
            display: inline-flex;

            .ri-external-link-line {
              font-size: 16px;
              color: $wave-blue;
              margin-right: 6px;
              line-height: 30px;
            }
          }

          .previewed {
            background-color: $dark-blue;

            span,
            .ri-external-link-line {
              color: $white;
            }
          }

          .go-preview:hover .ri-external-link-line {
            color: $sapphire;
          }

          .previewed:hover .ri-external-link-line {
            color: $white;
          }
        }
      }

      .ant-steps-item-title {
        width: 100%;
        font-size: 14px;
        padding-right: unset;
        color: $black !important;

        .hrbp-preview,
        .hrbp-review {
          width: 100%;
        }
      }

      .ant-steps-item-disabled {
        cursor: default;
      }

      .first-step {
        height: 78px;
      }

      .ant-steps-item-icon {
        width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 24px;
        margin-top: 4px;
        border-color: #634f7d;

        .ant-steps-icon {
          top: -1px;
        }
      }

      .process .ant-steps-item-icon {
        background-color: #634f7d;

        .ant-steps-icon {
          color: $white;
        }
      }

      .wait .ant-steps-item-icon {
        background-color: $white;

        .ant-steps-icon {
          color: #634f7d;
        }
      }
    }
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: 11px;
    opacity: 0.6;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #634f7d;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #634f7d;
  }
}

.ant-modal-body {
  margin-top: 0;
}

.ant-upload-list-item-actions {
  a {
    line-height: 36px;
  }

  .ant-btn.ant-btn-sm {
    padding: 0;
  }
}

@import "src/styles/variable";

.notification-model {
  .ant-modal-content {
    .ant-modal-body {
      padding: 24px 44px 34px 40px;
      margin: 0 auto;
      min-height: 210px;
      text-align: center;

      .ri-check-double-line {
        color: $light-flamingo;
        font-size: 28px;
      }

      p {
        font-style: normal;
        font-size: 16px;
        font-weight: $font-weight-normal;
        color: $black;
      }
    }
  }
}

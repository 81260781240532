@import "src/styles/variable";

.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.workday-link,
.workday-link:hover {
  color: $amethyst;
  word-break: break-all;
}

.invention-header {
  padding: 24px 24px 4px;
  color: $wave-blue;
  background: white;
  font-weight: $font-weight-bold;
  font-size: 24px;
  line-height: 32px;
}

.invention-header-tip {
  padding: 0 24px 16px;
  color: $amethyst;
  font-size: 16px;
  display: flex;
  background-color: $white;
  box-shadow: inset 0 -1px 0 #f0f0f0;

  p {
    margin: 4px 0 0;
  }
}

.invention-radio-form {
  margin-bottom: 0;

  .ant-form-item-label > label {
    align-items: flex-start;

    &::before {
      margin-top: 4px;
    }
  }

  .sub-form-label {
    display: block;
    margin-left: 0;
  }
}

.invention-label {
  display: block;
  font-weight: $font-weight-bold;
  font-size: 14px;
  line-height: 22px;
  color: $wave-blue;
  margin-top: 24px;
  margin-bottom: 8px;

  &.private-invention {
    margin-top: 4px;
  }
}

.add-invention-description {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
  color: $black;
}

.add-invention-description-en {
  color: $dark-gray;
}

.invention-header-tip-icon {
  margin-right: 8px;
  margin-top: 8px;

  svg {
    width: 16px;
    height: 16px;
    color: $amethyst;
  }
}

.invention-section {
  border: 1px solid #d5d9db;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0 24px;
  position: relative;
}

.remove-block-icon.anticon {
  position: absolute;
  right: 32px;
  top: 24px;
  font-size: 20px;
  color: $wave-blue;
}

.employee-edit-form {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin: 0 120px;

  .job-numbers {
    .ant-form-item {
      margin-bottom: 0;
    }

    .item-desc {
      margin-bottom: 0;
      margin-top: 4px;
      font-size: 14px;
      line-height: 22px;
      color: $dark-gray;
    }
  }

  .form-group {
    width: 100%;
    background-color: $white;
    padding: 24px;
    margin-bottom: 24px;

    .group-title {
      font-weight: $font-weight-bold;
      font-size: 14px;
      line-height: 22px;
      color: $wave-blue;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
    }

    .last-row .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-form-item.form-sub-item {
    margin-bottom: 20px;
  }

  .ant-form-item-label {
    overflow: visible;
  }

  .work-experience-item {
    .remove-icon {
      color: $wave-blue;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .work-experience {
    margin-bottom: 0;
  }

  .work-experience-group-title {
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 24px;
    color: $wave-blue;
    margin-bottom: 16px;
  }

  .ant-btn {
    margin-right: 8px;
  }

  .ant-steps {
    margin: 0 0 48px;
  }

  .commitment {
    margin-bottom: 24px;
  }

  .form-add-button {
    margin: 16px 0 0;
  }

  .submit-btn {
    display: inline-block;
    height: 40px;
  }
}

.label-tip {
  max-width: 420px;
}

@import "../../../../styles/variable";

.ri-time-line,
.ri-discuss-line {
  margin-right: 4px;
  font-size: 16px;
}

.main-page {
  .exit-container {
    text-align: right;
    padding: 16px 16px 0;
  }
}

.exit-approval-action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .exit-approval-action-btn {
    display: flex;
  }
}

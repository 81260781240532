@import "src/styles/variable";

.commitment {
  background-color: $white;

  .commitment-label {
    width: 100%;
    font-size: 24px;
    padding: 16px;
    color: $wave-blue;
    box-shadow: inset 0 -1px 0 #f0f0f0;
  }

  .commitment-content {
    font-size: 18px;
    font-weight: $font-weight-normal;
    line-height: 26px;
    padding: 24px 64px;

    .english-content {
      font-size: 18px;
      line-height: 26px;
      color: $dark-gray;
      margin-top: 12px;
      margin-bottom: 24px;
    }

    .ant-form-item {
      text-align: center;
      margin-bottom: 0;
    }
  }

  .confirm-text {
    color: $wave-blue;
    font-weight: $font-weight-normal;
    font-size: 18px;
    line-height: 26px;

    &::after {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
    }

    .config-text-en {
      color: $dark-gray;
      margin-left: 8px;
      font-weight: $font-weight-normal;
    }

    .confirm-symbol {
      color: #ff4d4f;
      font-size: 14px;
    }
  }

  .commitment-form-item.ant-form-item-has-error {
    .ant-checkbox-inner {
      border-color: $flamingo;
    }

    .confirm-text {
      color: $flamingo;
    }

    .ant-form-item-explain-error {
      margin-top: 0;
    }
  }
}

@import "src/styles/variable";

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.image-desc {
  width: 205px;
  height: fit-content;
  margin-bottom: 24px;
}

.image-title {
  color: $gray;
  font-weight: $font-weight-bold;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 8px;
}

.image-content {
  color: $dark-gray;
  font-weight: $font-weight-normal;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
}

.contact-us-des {
  color: $dark-gray;
  font-weight: $font-weight-normal;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
}

.contact-email {
  color: $wave-flamingo;

  &:hover {
    color: $wave-flamingo;
  }
}

.preview-image {
  max-width: 100%;
}

.file-loading {
  height: 400px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable-next-line */
.react-pdf__Document {
  width: 100%;

  /* stylelint-disable-next-line */
  .react-pdf__message {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* stylelint-disable-next-line */
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.file-load-failed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  flex-direction: column;

  img {
    width: 199px;
    height: 204px;
    margin-bottom: 50px;
  }
}

.file-load-failed-hint {
  display: flex;
  flex-direction: row;

  p,
  a {
    font-family: "Noto Sans SC", serif;
    font-size: 18px !important;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
  }

  p {
    color: #909090 !important;
  }

  a {
    text-decoration: underline;
    color: #47a1ad;
  }
}

@import "../../../../../styles/variable";

.approval-review-modal {
  .ant-modal-header {
    border: none;
    border-bottom: 1px solid #d5d9db;
    padding: 24px 64px 12px 30px;
  }

  .input-label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;

    span {
      font-size: 14px;
      color: $flamingo;
    }
  }

  .ant-modal-footer {
    border: none;
    padding: 0 24px 24px;

    .ant-btn {
      padding: 6px 16px;
      width: 68px;
      height: 34px;
      border-radius: 4px;

      span {
        font-size: 14px;
      }
    }
  }

  .ant-modal-body {
    padding: 24px 30px;
    font-size: 14px;
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: 11px;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #634f7d;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #634f7d;
  }
}

.review-checked {
  .ant-checkbox-wrapper {
    margin: 4px 0;
    display: flex;

    .ant-checkbox,
    .ant-checkbox-inner {
      height: 16px;
      width: 16px;
    }
  }
}

.hro-review-modal .ant-modal-content {
  .ant-modal-close {
    top: 10px;
  }

  .ant-modal-body {
    padding: 38px 18px 40px;

    .leave-hro-review,
    .home-office-hro-review {
      width: 424px;
      display: flex;
      align-items: center;

      .hro-review-btn, {
        display: inline-flex;
        justify-content: flex-end;
        flex: 1;
        text-align: right;
        margin-right: 16px;

        .review-cancel {
          margin-right: 10px;
          margin-left: 15px;
        }
      }
    }
  }
}

.approval-review-modal .hro-review-checked {
  .ant-steps-item-disabled {
    cursor: default;
  }

  .ant-steps-item-content .leave-hro-review,
  .home-office-hro-review {
    display: flex;

    .hro-review-btn {
      flex: 1;
    }
  }

  .first-step {
    height: 78px;
  }

  .review-note {
    font-weight: 400;
    line-height: 22px;
    color: $dark-gray;
  }

  .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    margin-top: 4px;
    border-color: #634f7d;

    .ant-steps-icon {
      top: -1px;
    }
  }

  .ant-steps {
    margin-bottom: 20px;

    .ant-steps-item-title {
      font-size: 14px;
      color: $black !important;
    }

    .process .ant-steps-item-icon {
      background-color: #634f7d;

      .ant-steps-icon {
        color: $white;
      }
    }

    .wait .ant-steps-item-icon {
      background-color: $white;

      .ant-steps-icon {
        color: #634f7d;
      }
    }
  }
}

.hro-review-modal .ant-modal-header {
  border: none;
  padding: 24px 64px 12px 30px;
  border-bottom: 1px solid #d5d9db;
}

@import "src/styles/variable";

.change-role-toggle {
  background: $light-gray;
  border-radius: 16px;
  height: 37px;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-gray;

  .role-button {
    font-size: $px14;
    font-weight: $font-weight-normal;
    background: transparent;
    display: flex;
    border: 0;
    border-radius: 16px;
    padding: 3px 8px;

    i {
      margin-right: 2px;
    }

    &.active {
      background: $wave-blue;
      color: $white;
    }
  }
}

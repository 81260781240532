@import "src/styles/variable";

.exit-approval-comment-drawer {
  .exit-approval-comment-drawer-driver {
    margin-top: 0;
  }

  .exit-approval-comment-history-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .exit-approval-comment-history-item {
    font-size: 14px;
    margin-bottom: 0;
    color: $dark-gray;
  }

  .ant-timeline-item {
    margin: 3.2px 13px 0 3px;
  }

  .ant-timeline-item-head.ant-timeline-item-head-custom.ant-timeline-item-head-blue {
    top: 3.6px;
  }

  .exit-approval-comment-history-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    color: $gray;
    line-height: 26px;
  }

  .ant-drawer-body {
    padding-bottom: 0;
  }
}

@import "src/styles/variable";

.form-label {
  display: block;
  color: $black;

  .info-icon {
    position: relative;
    margin-left: 4px;
    color: $gray;
  }
}

.sub-form-label {
  color: $dark-gray;
  margin-left: 4px;
}

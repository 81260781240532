@import "src/styles/variable";

.certificate-check-modal {
  .ant-modal-title {
    color: $black;
    font-size: 24px;
    line-height: 32px;
    min-width: 900px;
  }

  .ant-modal-body {
    padding: 35px 51px 25px 44px;
    margin: 0 auto;
    min-width: 900px;
  }

  .ant-modal-footer {
    min-width: 900px;
  }

  .certificate-previewer-container {
    background-color: #f7fafc;
    flex: auto;
    min-width: 0;
    min-height: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: block;
  }

  .certificate-previewer-item {
    height: 600px;
    padding: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  .employee-basic-info {
    display: flex;
    align-items: center;
    color: $amethyst;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;

    .employee-name {
      font-size: 24px;
      line-height: 32px;
      color: $black;
      margin-right: 16px;
    }

    .employee-id {
      font-size: 18px;
      line-height: 26px;
      margin-right: 48px;
    }

    .employee-info-item {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      margin-right: 8px;
      font-weight: $font-weight-normal;

      &::after {
        content: "";
        display: inline-block;
        margin-left: 8px;
        height: 24px;
        width: 1px;
        background-color: $middle-gray;
      }

      &.last-item::after {
        display: none;
      }
    }
  }

  .empty-container {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

.materials {
  margin: auto;
  min-width: 900px;
}

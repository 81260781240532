@import "src/styles/variable";

.apply-certification {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  min-width: 925px;
  white-space: nowrap;
}

.apply-certification-form {
  width: 100%;
  background-color: $white;
  padding: 24px;
  margin-bottom: 30px;
}

.apply-certification-title {
  color: $wave-blue;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: $font-weight-bold;
}

.apply-certification-tip {
  display: flex;
  margin-bottom: 17px;

  .info-icon {
    font-size: 20px;
    color: $amethyst;
    margin-top: 2px;
    margin-right: 10px;
  }

  p {
    line-height: 22px;
    font-size: 14px;
    font-weight: $font-weight-normal;
    color: $amethyst;
    margin: 0;

    &:first-child {
      margin-bottom: 3.5px;
    }
  }

  a {
    color: $amethyst;
    text-decoration: underline;

    &:hover {
      color: $wave-flamingo;
      text-decoration: none;
    }
  }
}

.certificate-type {
  margin: 0;
}

.small-text {
  color: $black;
}

.sub-form-label {
  color: $dark-gray;
  margin-left: 4px;
}

.image-desc-container {
  min-width: 850px;
  margin: 150px 0;
}

.show-ellipsis {
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.materials {
  margin: auto;
  max-width: 900px;
}

@import "src/styles/variable";

.contract-extension-confirm-modal {
  .contract-extension-form-label {
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-form-item-label > label.ant-form-item-required {
    &::before {
      display: none;
    }

    &::after {
      display: inline-block;
      margin-left: 4px;
      color: $flamingo;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .ant-modal-content {
    width: 486px;
  }

  .ant-modal-body {
    padding: 0 24px;
    margin: 0 auto;
  }

  .ant-form-item {
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 33px;
    }
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-footer {
    padding: 0 24px 24px;
  }

  .ant-radio-group {
    display: flex;
    justify-content: space-between;
  }

  .ant-radio-wrapper:last-child {
    margin-right: 0;
  }

  .contract-extension-confirm-button-disabled {
    height: 40px;
    padding: 8px 16px;
    font-weight: $font-weight-normal;
    font-size: 16px;
    line-height: 20px;
    border-radius: 6px;
    background-color: $gray;
    border-color: $gray;
    color: white;
    cursor: not-allowed;

    &:hover {
      background-color: $gray;
      border-color: $gray;
    }
  }
}

.contract-extension-list-header-headline3.headline3 {
  margin-left: 25px;
  margin-bottom: 0;
}

.contract-extension-list-table-common-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $white;
  padding-top: 16px;

  .contract-extension-table-common-search-bar {
    width: 440px;

    .ant-input {
      height: 40px;
    }

    .ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
      width: 40px;
      margin-right: 16px;
      border-color: #d5d9db;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      &:hover {
        border-color: #1890ff;
      }

      &:active {
        background-color: #1890ff;
      }

      svg {
        width: 16px;
        min-width: 16px;
      }
    }
  }

  .contract-extension-table-common-search-btn {
    margin-right: 24px;
    background: $wave-blue;
    color: $white;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

@import "src/styles/variable";

.employee-contract-list {
  .ant-btn-text a {
    margin-left: 8px;
  }

  .ant-btn-text:hover a {
    color: $sapphire;
  }

  .ant-btn-text:active a {
    color: $dark-blue;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;

  .selected-employee-number {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    padding: 5px 0;
  }

  .cancel-selection {
    margin-right: 8px;
  }
}

.finished-badge {
  margin-right: 14px;
  padding-top: 7px;
}

.auditing-badge {
  margin-right: 14px;
  padding-top: 7px;
}

.rejected-badge {
  margin-right: 14px;
  padding-top: 7px;
}

.certificate-status {
  text-align: center;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin-left: 18%;
}

.history-download-btn {
  float: right;
  margin-right: 18%;
}

.anticon.message-icon {
  font-size: 14px;
  color: $dark-blue;
  margin-right: 10px;

  &:hover {
    color: $sapphire;
  }
}

@import "src/styles/variable";

.material-check-modal {
  .ant-modal-title {
    color: $black;
    font-size: 24px;
    line-height: 32px;
  }

  .ant-modal-body {
    padding: 35px 51px 25px 44px;
    margin: 0 auto;
    min-width: 900px;
  }

  .employee-basic-info {
    display: flex;
    align-items: center;
    color: $amethyst;
    font-weight: $font-weight-bold;

    .employee-name {
      font-size: 24px;
      line-height: 32px;
      color: $black;
      margin-right: 16px;
    }

    .employee-id {
      font-size: 18px;
      line-height: 26px;
      margin-right: 48px;
    }

    .employee-info-item {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      margin-right: 8px;
      font-weight: $font-weight-normal;

      &::after {
        content: "";
        display: inline-block;
        margin-left: 8px;
        height: 24px;
        width: 1px;
        background-color: $middle-gray;
      }

      &.last-item::after {
        display: none;
      }
    }
  }

  .empty-container {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .ant-tabs {
    margin-top: 14px;
    color: $wave-blue;

    .ant-tabs-ink-bar {
      background-color: $flamingo;
    }

    .ant-tabs-content-holder {
      background-color: $background-blue;

      .empty {
        padding-top: 210px;
        color: $gray;
      }
    }

    .ant-tabs-tab:hover {
      color: $flamingo;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $flamingo;
      font-weight: $font-weight-bold;
    }

    .ant-tabs-content {
      height: 600px;
      padding: 24px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-tabs-tabpane {
        width: 100%;
        text-align: center;
      }
    }
  }
}

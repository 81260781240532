@import "src/styles/variable";

.file-size-limit {
  padding-left: 16px;
  font-size: 14px;
  line-height: 22px;
  color: $dark-gray;
}

.ant-upload-list.ant-upload-list-text {
  width: 317px;
}

.file-list-info {
  padding-top: 8px;
  color: $amethyst;
  font-family: "Noto Sans SC", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.file-list-error {
  padding-top: 8px;
  color: $flamingo;
  font-family: "Noto Sans SC", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@import "src/styles/common";

.e-sign-button {
  background-color: $wave-blue;
  padding: 16px 64px;
  outline: none;
  border: none;
  font-size: 18px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.e-sign-button:hover {
  background-color: #59727f;
}

.e-sign-button:disabled {
  cursor: not-allowed;
  background-color: #909090;
}

@import "src/styles/variable";

.to-be-operated-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 144px;
}

.operate-title {
  font-size: 28px;
  text-align: center;
  font-weight: $font-weight-bold;
  margin-bottom: 24px;
}

.operate-button-list {
  display: flex;
  flex-direction: column;
  width: 272px;
  height: 300px;
}

.operation-button-wrapper .e-sign-button {
  width: 100%;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-operate-item-image {
  width: 168px;
  height: 172px;
  margin-top: 24px;
}

.no-operate-item-image + div {
  margin-top: 24px;
  font-size: 18px;
  color: $dark-gray;
}

.operate-button-list .operation-button-wrapper {
  margin-top: 24px;
  display: block;
}

.operate-button-list .anchor-wrapper {
  position: relative;
}

@import "src/styles/variable";

.title {
  margin-right: 16px;
}

.employee-id {
  color: #634f7d;
}

.ant-picker {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.individual-info-status {
  .ant-tooltip .ant-tooltip-inner {
    color: $amethyst;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 22px;
    text-shadow: none;
  }
}

.sub-title {
  margin-bottom: 24px;
}

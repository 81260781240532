.certificate-management {
  text-align: center;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.status {
  width: 120px;
  text-align: left;
  padding-left: 16px;
}

@import "src/styles/variable";

.legal-validity-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 40px 24px 42px;

      .legal-validity-modal-title {
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 24px;
      }

      .legal-validity-modal-content {
        font-weight: $font-weight-normal;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 8px;
      }

      .legal-validity-modal-link {
        font-weight: $font-weight-normal;
        font-size: 14px;
        line-height: 22px;
        color: $gray;
        margin-bottom: 40px;
      }
    }
  }
}

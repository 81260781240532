@import "../../../styles/variable";

.main-page {
  .exit-container {
    text-align: right;

    .add-exit-btn {
      margin-right: 24px;
      background: $wave-blue;
      color: $white;
      border: 1px solid #000;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: inline-flex;

      .ri-user-add-fill {
        font-size: 16px;
        margin-right: 4px;
      }
    }

    .exit-table-common-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 14px;

      .exit-table-common-search-bar {
        width: 440px;

        .ant-input {
          height: 40px;
        }

        .ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
          width: 40px;
          margin-right: 16px;
          border-color: #d5d9db;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          &:hover {
            border-color: #1890ff;
          }

          &:active {
            background-color: #1890ff;
          }

          svg {
            width: 16px;
            min-width: 16px;
          }
        }
      }

      .exit-table-common-search-btn {
        margin-right: 24px;
        background: $wave-blue;
        color: $white;
        border: 1px solid #000;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
